.App {
  text-align: center;
  font-family: 'Lexend Deca',sans-serif;
  font-weight: 400;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.navbar {
  background-color: #252a33;
  box-shadow: 0px 2px 15px #000;
  /* border-bottom: 3px solid #0c8c6a; */
}

.navbar-expand {
  justify-content: center;
}

.navbar .container {
  margin: 2px 50px !important;
}


.navbar-nav a{
  color: #fff;
  font-weight: 700;
  line-height: 35px;
}

.footer {
  margin-top: 50px;
  background-color: #252a33;
  color: #fff;
  border-top: 5px solid #0c8c6a;
}

.footer a{
  color: #fff;
  text-decoration: none;
}

.footer-container{
  max-width: 1105px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 28px;
  padding-left: 28px;
}

.footerLogo{
  display: flex;
  justify-content: center;
  padding: 25px 0;
}

.footerLogo img{
  margin-top: 40px;
}

.footerText{
  margin: 0px auto 40px;
  max-width: 400px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 2px;
}

.footer-social{
  display: flex;
  padding-top: 56px;
  padding-bottom: 56px;
  -webkit-justify-content: center;
  align-items: center;
}

.socialLink{
  display: flex;
  margin-right: 28px;
  margin-left: 28px;
  -webkit-box-align: center;
}

.socialLink img{
  margin-right: 14px;
}

.socialImage {
  width: 22px;
  height: 22px;
}

.white-border{
  height: 1px;
  background-color: hsla(0,0%,100%,.15);
}


.copyright{
  padding-top: 35px;
  padding-bottom: 35px;
  background-color: rgba(0,0,0,.2);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 600;
}

.cr-row {
  max-width: 1105px;
  position: relative;
  display: flex;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 28px;
  padding-left: 28px;  
}

.cr-left{
  flex: auto;
}

.cr-right a{  opacity: 0.5; }

.cr-right a:hover {  opacity: 1; }



/* section one */

.sectionOne {
  background-color: #D7CCAD;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  justify-content: center;

  box-shadow: 0px 0px 15px;
  border-bottom: 5px solid #252a33;

  /* box-shadow: 0px 0px 20px 0px #333333; */
}

.sectionOne .OneThird {
  width: 45%;
}

.sectionOne .TwoThird {
  width: 45%;
}

.sectionOne h1 {
  color: #245758;
  text-align: center;
  margin: 0 0 28px;
  font-family: 'Anderson Grotesk',sans-serif;
  font-size: 45px;
  line-height: 52px;
  font-weight: 900;
}

.sectionOne p {
  font-size: 24px;
  line-height: 26px;
  margin-top: 0;
  margin-bottom: 45px;
  text-align: center;
  letter-spacing: 2px;
  font-weight: 500;
  padding: 0;
}

.sectionOne a {
    box-shadow: 0px 0px 10px 0px;
    border: 1px solid;
    font-weight: 500;
    padding: 5px 25px;
    background-color: #FFBF00;
    font-size: 20px;
    line-height: 35px;
    text-align: center;
    color: #000;
    text-decoration: none;
    border-radius: 15px;

    display: block;
    width: fit-content;
    margin: auto;
}

.OneThird .col{
  padding: 50px 60px;
}

.TwoThird .col {  
  width: fit-content;
  margin: auto;
}


/* Section two */

.sectionTwo {
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
}

.sectionTwo .OneThird {
  width: 40%;
}

.sectionTwo .TwoThird {
  width: 60%;
  padding-right: 5%;
}


.sectionTwo .logo {
  width: 250px;
  height: auto;
  margin-bottom: 10px;
}

.sectionTwo .TwoThird .col {
  padding: 50px 80px 50px 0px;
}

.sectionTwo .imgBlock{ 
  text-align: center;
}

.sectionTwo .imgBlock img{
  border-radius: 10px;
  box-shadow: 0px 0px 10px;
}

.sectionTwo h1{

  font-family: 'Anderson Grotesk',sans-serif;
  color: #245758;
  font-weight: 800;
  line-height: 35px;
  font-size: 30px;

}

.sectionTwo p{
  font-size: 20px;
  letter-spacing: 1px;
}



/* Section three */

.sectionThree .col{
  text-align: center;
}


.sectionThree .textBlock {
  margin: 40px auto 100px;
  max-width: 600px;
}

.sectionThree h1{
  /* text-shadow: 0px 0px 5px #fdb717; */
  font-family: 'Anderson Grotesk',sans-serif;
  color: #245758;
  font-weight: 800;
  line-height: 45px;
}

.sectionThree p{
  font-size: 20px;
  letter-spacing: 1px;
}

.sectionThree img{
  height: auto;
  width: 100%;
  box-shadow: 0px 0px 20px #000000 ;
}

.sectionThree .divider {
  width: 150px;
  border-top: 5px solid #00857b;
  border-radius: 10px;
  margin: 30px auto;
  opacity: 1;
}

/* section four */

.DesktopContactform{
  padding: 10% 25%;
}


.sectionFour .textBlock {
  margin: 40px 20px;
  text-align: center;
}

.sectionFour h1{
  font-family: 'Anderson Grotesk',sans-serif;
  color: #245758;
  font-weight: 800;
  line-height: 45px;

}

.sectionFour p{
  font-size: 20px;
  letter-spacing: 1px;
}


@media only screen and (min-width: 800px) {
  .phoneView {
    display: none;
  }
  
  .sectionTwo p {
    text-align: justify;
  }

  .navbar-nav .nav-link {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .sectionTwoTablet{
    display: none;
  }

}

/* Mobile View */

@media only screen and (max-width: 799px) {

  .desktopView {
    display: none;
  }

  .navbar .container {
    margin: 2px 20px !important;
  }

  /* section One */

  .sectionOne, .sectionTwo{
    display: block;
  }

  .sectionOne h1{
    font-size: 35px;
    line-height: 42px;
    margin: 0 0 15px
  }

  .sectionOne p{
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .sectionOne .textCol {
    padding: 0 40px;
    margin-top: 25px;
  }

  .sectionOne .imgCol {
    text-align: center;
  }

  .sectionOne img{
    margin-top: 50px;
  }

  .sectionTwo .textBlock {
    margin: 40px 20px 100px;
    text-align: center;
  }

  .sectionTwo h1{
    line-height: 45px;
    font-size: 40px;
  }

  .sectionTwo .logo {
    width: 300px;
    margin-bottom: 30px;
  }

  .sectionTwo img {
    width: 85%;
  }

  .sectionThree .textBlock {
    margin: 40px auto 100px;
  }

  .sectionThree h1 {
    line-height: 38px;
  }
  
  .MobileContactform{
    padding: 10%;
  }

  .sectionFour .textBlock {
    margin: 40px 20px;
    text-align: center;
  }

  .sectionFour h1 {
    line-height: 32px;
  }

  /* footer */

  .callToAction{
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-justify-content: center;
    align-items: center;
    margin-bottom: 40px;
  }

  .callToAction img {
    width: 35px;
  }

  /* copyright */

  .cr-row {
    display: block;
    text-align: center;
  }

  .cr-row p{
    margin-bottom: 0;
    letter-spacing: 1.5px;
  }

}


@media (min-width: 768px) and (max-width: 1024px) {
    .sectionTwoDesktop {
      display: none;
    }

    .sectionOne h1 {
      font-size: 30px;
      line-height: 35px;
    }

    .sectionTwo .textBlock {
      margin: 40px 20px 100px;
      text-align: center;
    }
  
    .sectionTwo h1{
      line-height: 45px;
      font-size: 40px;
    }
  
    .sectionTwo .logo {
      width: 300px;
      margin-bottom: 30px;
    }

    .sectionTwoTablet{
      display: block !important;
    }
  
    .sectionTwo img {
      width: 85%;
    }

    /* .sectionTwo p {
      text-align: center;
    } */

    .sectionTwo img {
      width: 75%
    }
  
    .sectionTwo .textBlock {
      margin: 40px 60px 100px;
    }

    .sectionTwo .logo {
      width: 350px;
      margin-bottom: 40px;
    }

}

/* Contact Form */

.contactForm{
  display: grid;
}

.contactForm input{
  border: 2px solid #245758 !important;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 0.28rem 0.75rem;
  color: #245758;
}

.contactForm input::placeholder {
  color: #000000;
  opacity: 1;
  font-weight: 500;
}
  
.contactForm button{
border-radius: 5px;
width: 100%;
font-weight: 500;
background-color: #245758;
color: #ffffff;
padding: 0.28rem 0.75rem;
}

.afterSubmitText{
  text-align: center;
}